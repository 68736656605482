import React, { useContext, useEffect } from 'react';
import { StoreContext } from '../../store/store';
import './Footer.scss';
import { text } from '../../utils/global';
import { useTranslation } from 'react-i18next';
import cookieConsent from '../../utils/cookieConsent';
// Action types
import { POPUP } from '../../constants/actionTypes';

function VFFooter() {
  const globalState = useContext(StoreContext);
  const { dispatch, state } = globalState;

  const { t } = useTranslation();
  const { checkShowBanner } = cookieConsent();

  // handle reCAPTCHA policy links
  let countryCodeQueryParam = '';
  let recaptchaPolicyText = '';
  let recaptchaPolicyTextAsArray = [];

  // add locale query param
  if (window.config && window.config.market && window.config.market.locale) {
    countryCodeQueryParam = '?hl=' + window.config.market.locale.substring(3, 5).toLowerCase();
  }

  // replacements
  let replacements = [];

  if (text('label.for.recaptcha')) {
    recaptchaPolicyText = text('label.for.recaptcha');

    // convert sting to an array
    recaptchaPolicyTextAsArray = recaptchaPolicyText.split(/{(.*?)}/);

    // find placeholders in the string
    var placeholders = recaptchaPolicyText.match(/[^{\}]+(?=})/g);

    // replacements (considering there are only two links...)
    replacements = [
      <a
        key={'policy'}
        onClick={() => handleRecaptchaLink('https://policies.google.com/privacy' + countryCodeQueryParam)}
      >
        {placeholders[0]}
      </a>,
      <a key={'terms'} onClick={() => handleRecaptchaLink('https://policies.google.com/terms' + countryCodeQueryParam)}>
        {placeholders[1]}
      </a>,
    ];

    for (let i = 0; i < placeholders.length; i++) {
      for (let j = 0; j < recaptchaPolicyTextAsArray.length; j++) {
        if (recaptchaPolicyTextAsArray[j] == placeholders[i]) {
          // replace
          recaptchaPolicyTextAsArray[j] = replacements[i];
        }
      }
    }
  }

  let manageLinkLabel = text('cookieManager.aboutCookieLink');

  if (!window.config.smapiEnabled && window.config.analytics && !window.config.analytics.tealium_enabled) {
    manageLinkLabel = text('cookieManager.cookieBanner.viewCookies');
  }

  const handleRecaptchaLink = (url) => {
    window.open(url);
  };

  useEffect(() => {
    console.log('Footer loaded');
  }, []);

  const showTermsAndConditions = () => {
    dispatch({
      type: POPUP,
      popup: 'TERMS_AND_CONDITIONS',
    });
  };

  const showPrivacyPolicy = () => {
    dispatch({
      type: POPUP,
      popup: 'PRIVACY_POLICY',
    });
  };

  const showManageCookies = () => {
    dispatch({
      type: POPUP,
      popup: 'MANAGE_COOKIES',
    });
  };
  return (
    <>
      <footer className="footer-wrapper">
        <div className={`footer`}>
          <ul className="footer-nav">
            <li className="footer-navbar-text">
              <a id="termsOfUse" onClick={showTermsAndConditions}>
                {text('label.for.terms_conditions')}
              </a>
            </li>
            <li className="footer-navbar-text">
              <a onClick={showPrivacyPolicy} id="privacy">
                {text('label.for.privacy_policy')}
              </a>
            </li>
           
            {
              !checkShowBanner() &&
              window.config &&
              window.config.cookieManagement.manageCookieLinkEnabled && (
                <li className="footer-navbar-text">
                  <a onClick={showManageCookies} id="cookiePopup">
                    {manageLinkLabel}
                  </a>
                </li>
              )}
          </ul>

          {state.data && state.data.recaptcha && (
            <div className="recaptcha-footer-text">{recaptchaPolicyTextAsArray}</div>
          )}

          <div className="footer-copyright">
            <span>{text('footer.label')}</span>
          </div>
        </div>
      </footer>
    </>
  );
}
export default VFFooter;
