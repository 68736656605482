import { useState, useEffect, useContext } from 'react';
import { StoreContext } from '../../store/store';
import { t, validateIdentifier } from '../../utils/global';
import { RESPONSE_ERROR_CLEAR } from '../../constants/actionTypes';

const useForm = (callback) => {
  const [values, setValue] = useState({ identifier: '' });
  const [errors, setErrors] = useState({});
  const [buttonType, setButtonType] = useState('primary');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [identifierTitle, setIdentifierTitle] = useState('hidden');
  const [identifierPadding, setIdentifierPadding] = useState('paddingTop20');
  const [isCheckboxDisabled, setIsCheckboxDisabled] = useState(false);
  const [identifierType, setIdentifierType] = useState('');
  let { state, dispatch } = useContext(StoreContext);
  const [isValid, setIsValid] = useState(state?.data?.identifier?.value !== undefined ? true : false);
  
  
  useEffect(() => {
    setIdentifierType(state.data.identifier.type);
  }, [state.data.identifier.type]);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    }
    if (state?.data?.identifier?.value !== undefined) {
      setIsValid(true);
    }
  }, [errors]);

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    setIsSubmitting(true);
    validateForm();
    event.target.blur();
  };

  const clearErrors = () => {
     if (state.errorObj && state.errorObj.msg) {
       dispatch({
         type: RESPONSE_ERROR_CLEAR,
         payload: {},
       });
     }
  }

  const handleChange = (event) => {
    clearErrors();
    const { name, value } = event?.target || {};
    if (!name) return;

    updateStateOnChange(name, value);
  };

  const updateStateOnChange = (name, value) => {
    setValue((values) => ({
      ...values,
      [name]: value.trim(),
    }));

    const isValidInput = value.trim() !== '' && value.length > 0;

    setIsCheckboxDisabled(!isValidInput);
    setButtonType('primary');
    setIsValid(isValidInput);
    setIdentifierTitle(isValidInput ? '' : 'hidden');
    setIdentifierPadding(isValidInput ? '' : 'paddingTop20');
    if (isValidInput) setIsSubmitting(false);
  };
  

  const handleBlur = (event) => {
    setErrors({});
    validateForm();
  };

  const handleFocus = (event) => {
    
  };

  function validateForm() {
    let errMsg = validateIdentifier(identifierType, values.identifier, state.data.context);
    if (errMsg !== undefined && errMsg !== false) {
      setErrors((errors) => ({ ...errors, msg: errMsg, className: 'inline_error' }));
      setButtonType('primary');
      setIsValid(false);
    } else {
      setErrors({});
    }
  }

  const resetValues = () => {
    setValue((values) => ({ ...values, identifier: '' }));
    setButtonType('primary');
    setIsValid(false);
    setIdentifierTitle('hidden');
    setIdentifierPadding('paddingTop20');
    setIsSubmitting(false);
    setErrors({});
  };

  const resetError = () => {
    setValue((values) => ({ ...values, identifier: '' }));
    setIdentifierTitle('hidden');
    setIdentifierPadding('paddingTop20');
    setErrors({});
  };

  const handleKeyPress = (event) => {
    var charCode = event.which ? event.which : event.keyCode;
    if (charCode == 13) {
      handleSubmit(event);
    } else if (identifierType === 'tel') {
      var charCode = event.which ? event.which : event.keyCode;
      if ((charCode >= 48 && charCode <= 57) || charCode == 8 || charCode == 0) {
        return true;
      } else {
        event.preventDefault();
      }
    } else {
      return true;
    }
  };

  

  return {
    errors,
    values,
    identifierTitle,
    setIdentifierTitle,
    identifierPadding,
    setIdentifierPadding,
    buttonType,
    setButtonType,
    isCheckboxDisabled,
    setIsCheckboxDisabled,
    resetValues,
    resetError,
    handleSubmit,
    handleChange,
    handleBlur,
    handleFocus,
    isValid,
    setIsValid,
    isSubmitting,
    handleKeyPress,
  };
};

export default useForm;
