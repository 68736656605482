import React, { useContext } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { getSmapiPageName } from '../../constants/smapiMapper';
import { Link } from '@vfid/lib-id-fe-shared';
import { DependencyContext } from '../../context/DependencyContext';
import useValidateForm from './useValidateForm';
import InfoButton from '../InfoButton/InfoButton';
import IdentifierDisplay from '../../components/Identifier/IdentifierDisplay';
import SubmitButton from '../Button/SubmitButton';
import BackButton from './BackButton';
import RegisterAction from './RegisterAction';
import { handleLinks } from './Handlers';
import RememberCheckbox from './RememberCheckbox';
import { getMarket } from '../../utils/Helpers';
import { useLinkHandler } from '../../hooks/useLinkHandler';
import Identifier from '../Identifier/Identifier';
import WithOpcoWrapper from '../Identifier/WithOpcoWrapper';

const Validate = (props) => {
  const { analyticsTealium, storeContext, useReCAPTCHA, logger, ServiceRequest, text } = useContext(DependencyContext);
  const { trackPageEvent } = analyticsTealium();
  let { state } = useContext(storeContext);
  const market = getMarket(state);
 const handleLinkClick = useLinkHandler(logger, trackPageEvent, ServiceRequest, props.RequestCallBack);
  const {
    isRemember,
    setRemember,
    isInfoShown,
    setInfoShown,
    errors,
    values,
    identifierTitle,
    identifierPadding,
    buttonType,
    isValid,
    setIsValid,
    isSubmitting,
    handleSubmit,
    handleChange,
    handleBlur,
    handleFocus,
    resetError,
    handleKeyPress,
    isCheckboxDisabled,
    recaptchaProps,
    resetValues,
    identifier,
  } = useValidateForm(useReCAPTCHA, state, ServiceRequest, text, props.RequestCallBack);
  const identifierLabel = state.data.identifier.type === 'tel' ? 'msisdn' : state.data.identifier.type;
  return (
    <>
      <div className="section-form">
        <div className="validate-form">
          <div className={`box`}>
            {identifier !== undefined && (
              <IdentifierDisplay
                market={market}
                handleLinkClick={handleLinkClick}
                state={state}
                identifier={identifier}
                text={text}
                identifierLabel={identifierLabel}
              />
            )}

            {identifier === undefined && (
              <>
                {state.data?.showCredentialsHelpText && state.data?.identifier?.type === 'username' && (
                  <InfoButton text={text} isInfoShown={isInfoShown} setInfoShown={setInfoShown} />
                )}

                {state.data?.identifier.type === 'tel' ? (
                    <WithOpcoWrapper
                      identifierTitle={identifierTitle}
                      className={errors.className}
                      identifierPadding={identifierPadding}
                      value={values.identifier}
                      errMsg={errors.msg}
                      handleChange={handleChange}
                      handleKey={handleKeyPress}
                      handleBlur={handleBlur}
                      market={market}
                      handleFocus={handleFocus}
                    />
                  
                ) : (
                  <Identifier
                    identifierTitle={identifierTitle}
                    className={errors.className}
                    identifierPadding={identifierPadding}
                    value={values.identifier}
                    errMsg={errors.msg}
                    handleChange={handleChange}
                    handleKey={handleKeyPress}
                    handleBlur={handleBlur}
                    market={market}
                    handleFocus={handleFocus}
                  />
                )}
              </>
            )}

            {state.data.links['urn:vodafoneid:forgotUsername'] && (
              <Link
                id="forgotUsername"
                onHandleClick={() => {
                  const tealiumPayload = {
                    eventCategory: 'link',
                    eventLabel: text(`forgot.${state.data.identifier.type}.link.text`),
                  };

                  const smapiPayload = {
                    message: `clicks on forget ${state.data.identifier.type}`,
                  };

                  const actionPayload = {
                    link: state.data.links['urn:vodafoneid:forgotUsername'],
                    market,
                    context: state.data.context,
                  };
                  handleLinkClick(state.data.context, { tealiumPayload, smapiPayload }, actionPayload);
                }}
                text={text('forgot.' + state.data.identifier.type + '.link.text')}
              />
            )}
            {state.data.fields !== undefined && state.data.fields.rememberMyUsernameCheckbox !== undefined && (
              <RememberCheckbox
                isRemember={isRemember}
                handleCheckbox={(e) => setRemember(e.target.checked)}
                isCheckboxDisabled={isCheckboxDisabled} // assuming no actual condition for disabling
                label={text(state.data.fields.rememberMyUsernameCheckbox[0].name)}
              />
            )}
          </div>
          <div className={`validate-btn-wrapper-${state.data?.identifier?.type}`}>
            {state.data.links['urn:vodafoneid:submitIdentifier'] && (
              <SubmitButton
                isSubmitting={isSubmitting}
                isValid={isValid}
                handleSubmit={handleSubmit}
                text={text}
                trackPageEvent={trackPageEvent}
                state={state}
              />
            )}

            {state.data.links['urn:vodafoneid:back'] && (
              <BackButton
                trackPageEvent={trackPageEvent}
                text={text}
                logger={logger}
                ServiceRequest={ServiceRequest}
                state={state}
                callBack={props.RequestCallBack}
                resetError={resetError}
                getSmapiPageName={getSmapiPageName}
              />
            )}
        </div>
          {state.data.links['urn:vodafoneid:register'] && (
            <RegisterAction
              market={market}
              trackPageEvent={trackPageEvent}
              text={text}
              state={state}
              handleLinks={handleLinks}
              callBack={props.RequestCallBack}
              logger={logger}
              ServiceRequest={ServiceRequest}
            />
          )}

          {state.data && state.data.recaptcha && (
            <>
              <ReCAPTCHA {...recaptchaProps} />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Validate;
