import { useState, useRef } from 'react';

export const useReCAPTCHA = (sitekey) => {
  const [token, setToken] = useState(null);
  const [error, setError] = useState(null);
  const recaptchaRef = useRef(null);

  const generateToken = async () => {
    if (recaptchaRef.current) {
      try {
        const newToken = await recaptchaRef.current.executeAsync();
        setToken(newToken);
        return newToken;
      } catch (e) {
        console.error('Error generating recaptcha token=', e);
        setError(e);
        return null;
      }
    }
  };

  // callback when captcha succesfully resolved
  const handleCaptchaChange = (newToken) => {
    setToken(newToken);
    setError(null);
  };

  const resetRecaptcha = () => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
    setToken(null);
  };

  const verifyCaptcha = () => {
    if (!token) {
      setError('Please complete the reCAPTCHA challenge.');
      return false;
    }
    return true;
  };

  const locale = () => {
    return window.config && window.config.market && window.config.market.locale
      ? window.config.market.locale.substring(0, 2)
      : 'en_GB';
  };

  const onExpired = () => {
    setToken(null), setError('Captcha Expired. Please try again later');
  };
  return {
    token,
    error,
    verifyCaptcha,
    resetRecaptcha,
    recaptchaRef,
    generateToken,
    recaptchaProps: {
      ref: recaptchaRef,
      size: 'invisible',
      sitekey: sitekey,
      onChange: handleCaptchaChange,
      hl: locale(),
      onExpired,
    },
  };
};
