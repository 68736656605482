import React from 'react';
import { Button } from '@vfid/lib-id-fe-shared';

const BackButton = ({
  trackPageEvent,
  text,
  logger,
  ServiceRequest,
  state,
  callBack,
  resetError,
  getSmapiPageName,
}) => {
  const handleBack = (event) => {
    if(event) {
      event.preventDefault();
    }
    resetError();
    logger.info(`${getSmapiPageName(state.data.context)}: clicks on back button`);
    ServiceRequest(state.data.links['urn:vodafoneid:back'], null, callBack, 'self', {
      page: state.data.context,
    });
  };

  return (
    <div className="btn-wrapper" key="btn2">
      <Button
        type="button"
        priority="secondary"
        label={text('back.button.label')}
        id="backButton"
        onHandleClick={(event) => {
          trackPageEvent(state.data.context, { eventCategory: 'button', eventLabel: text('back.button.label') }),
            handleBack(event);
        }}
      />
    </div>
  );
};

export default BackButton;
