
import { handleLink } from "../../utils/Services";
import { getTarget } from "../../utils/Helpers";

export function handleLinks(link, callBack, target, desc, market, context) {
  // flushing smapi events as it, link may redirect to another page
  if (target === null) {
    window.submitter.flushBufferedEvents().then((res) => {
      console.log('events flushed as user redirected');
    });
  }
 
  target = getTarget(market);
  //TODO: not sure we should reset error here
  // resetError();
  handleLink(link, callBack, target, {
    page: context,
  });
}


//Remember My Username Checkbox Handler
export function handleCheckbox(e) {
  if (e.target.checked) {
    logger.info(`${getSmapiPageName(state.data.context)}: remember me checked`);
  } else {
    logger.info(`${getSmapiPageName(state.data.context)}: remember me unchecked`);
  }
  setRemember(e.target.checked);
}
