import React, { useState, useEffect, useContext } from 'react';
import { StoreContext } from '../../store/store';
import { text } from '../../utils/global';
import Opco from './Opco';

const WithOpcoWrapper = (props) => {
  let { state } = useContext(StoreContext);
  const [focusState, setFocusState] = useState(false);

  //let market = {};
  let placeholder,
    label,
    minLength,
    maxLength,
    validation = undefined;
  let identifierType = state.data.identifier.type;
  let opco = false;
  const identifierId = props.id ? props.id : 'identifier';
  if (window.config !== undefined) {
    switch (identifierType) {
      //Email address
      case 'email':
        placeholder = text('placeholder.for.identifier.email');
        label = text('label.for.identifier.email');

        //validation = window.config.market["emailRegEx"];
        // Min/Max length validation
        minLength = window.config.market['minEmailChars'];
        maxLength = window.config.market['maxEmailChars'];
        break;
      //MSISDN
      case 'tel':
        identifierType = 'text';
        placeholder = text('placeholder.for.identifier.msisdn');
        label = text('label.for.identifier.msisdn');

        //validation = window.config.market["msisdnRegEx"];
        // Min/Max length validation
        minLength = window.config.market['minMsisdnChars'];
        maxLength = window.config.market['maxMsisdnChars'];

        //Opco
        opco = (
          <Opco
            countryCode={props.market !== undefined ? props.market.countryCode.toLowerCase() : undefined}
            msisdnPrefix={props.market !== undefined ? '+' + props.market.msisdnPrefix : ''}
          ></Opco>
        );
        break;
      // Username
      case 'username':
        identifierType = 'text';
        placeholder = text('placeholder.for.identifier.username');
        label = text('label.for.identifier.username');

        //validation = window.config.market["usernameRegEx"];
        // Min/Max length validation
        minLength = window.config.market['minUsernameChars'];
        maxLength = window.config.market['maxUsernameChars'];
        break;
      // Nif
      case 'nif':
        identifierType = 'text';
        placeholder = text('placeholder.for.identifier.nif');
        label = text('label.for.identifier.nif');

        //validation = window.config.market["nifRegEx"];
        // Min/Max length validation
        minLength = window.config.market['minNifChars'];
        maxLength = window.config.market['maxNifChars'];
        break;
    }

    const handleFocus = (e) => {
      setFocusState(true);
      if (props.handleFocus) {
        props.handleFocus(e);
      }
    };

    const handleBlur = (e) => {
      setFocusState(false);

      if (props.handleBlur) {
        props.handleBlur(e);
      }
    };

    return (
      <>
        <div className="standalone-field-label">{label}</div>

        <div className={props.className}>
          {opco}
          <div className="input-wrapper">
            <div className={'input-border-box' + (focusState ? ' focused' : '')}>
              <div>
                <div className={`box-title ${props.identifierTitle}`}>{label}</div>
                <div className={`box-contents ${props.identifierPadding}`} id="box-contents">
                  <input
                    type="text"
                    name="identifier"
                    id={identifierId}
                    className="msisdninputbox"
                    autoComplete="off"
                    minLength={minLength}
                    maxLength={maxLength}
                    placeholder={placeholder}
                    onFocus={handleFocus}
                    onChange={props.handleChange}
                    onKeyPress={props.handleKey}
                    onBlur={handleBlur}
                    value={props.value}
                    data-testid={props.dataTestID}
                  />
                </div>
              </div>
            </div>
            <div className={`box-error`}>
              {<i id="inputIcon" className={`fa fa-user inline-error-icon`}></i>}
              <span>{props.errMsg}</span>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <React.Fragment></React.Fragment>;
  }
};

export default WithOpcoWrapper;
