import React from 'react';
import { Button } from '@vfid/lib-id-fe-shared';
/**
 * As EAA enabled, button will always enabled, disregarding form is valid or invalid
 */
const EAA_CONTRAST_RULE = true;
const SubmitButton = ({ buttonType, isSubmitting, isValid, trackPageEvent, handleSubmit, text, state }) => {
  return (
    <div className="btn-wrapper" key="btn1">
      <Button
        type="button"
        priority={buttonType}
        label={text('submit.button.label')}
        id="continueButton"
        loading={isSubmitting === true}
        disabled={!isValid && !EAA_CONTRAST_RULE}
        dataTestID={'CONTINUEBUTTON'}
        onHandleClick={(event) => {
          trackPageEvent(state.data.context, { eventCategory: 'button', eventLabel: text('submit.button.label') }),
            handleSubmit(event);
        }}
      />
    </div>
  );
};

export default SubmitButton;
