const BRIDGE = 'DataActivationController';
const DONE_SELECTED_CALLBACK = 'doneSelected';

export function getBridge() {
  let bridge = window[BRIDGE];

  if (!bridge) {
    //try some exotic way to get it

    try {
      // eslint-disable-next-line
      bridge = DataActivationController;
    } catch (error) {
      //skip this method and try another one
    }

    if (!bridge) {
      try {
        // eslint-disable-next-line no-undef
        bridge = global[BRIDGE];
      } catch (err) {
        //skip this method and try another one
      }
    }

    if (!bridge) {
      try {
        // eslint-disable-next-line
        bridge = eval(BRIDGE);
      } catch (err) {
        //cannot find bridge
      }
    }
  }
  return bridge;
}

export function hasCallback() {
  let bridge = getBridge();

  if (bridge) {
    return !!bridge[DONE_SELECTED_CALLBACK];
  }

  return false;
}
