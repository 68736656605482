import React from 'react';
import WithIntroLink from '../Link/WithIntroLink';
import { useLinkHandler } from '../../hooks/useLinkHandler';
import { Button } from '@vfid/lib-id-fe-shared';

const RegisterAction = ({ state, text, trackPageEvent, market, callBack, logger, handleLinks, ServiceRequest }) => {
  const handleLinkClick = useLinkHandler(logger, trackPageEvent, ServiceRequest);
  return state.data.links['urn:vodafoneid:register'].control !== undefined &&
    state.data.links['urn:vodafoneid:register'].control === 'button' ? (
    <Button
      priority="secondary"
      label={text('register.link.text')}
      id="register"
      onHandleClick={(e) => {
        e.preventDefault();
        const tealiumPayload = { eventCategory: 'button', eventLabel: text('register.link.text') };
        const smapiPayload = {
          message: `clicks on register button`,
        };

        const actionPayload = {
          link: state.data.links['urn:vodafoneid:register'],
          market,
          context: state.data.context,
        };
        handleLinkClick(state.data.context, { tealiumPayload, smapiPayload }, actionPayload);
      }}
    />
  ) : (
    <WithIntroLink
      id="register"
      onHandleClick={() => {
        const tealiumPayload = { eventCategory: 'link', eventLabel: text('register.link.text') };

        const smapiPayload = {
          message: `clicks on register link`,
        };

        const actionPayload = {
          link: state.data.links['urn:vodafoneid:register'],
          market,
          context: state.data.context,
        };

        handleLinkClick(state.data.context, { tealiumPayload, smapiPayload }, actionPayload);
         
      }}
      intro={text('register.link.intro.text')}
      text={text('register.link.text')}
    />
  );
};

export default RegisterAction;
