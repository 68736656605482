import React from 'react';
import { Link } from '@vfid/lib-id-fe-shared';

const IdentifierDisplay = ({ state, identifier, text, identifierLabel, market, handleLinkClick }) => {
  return (
    <div className="user-name">
      <div className="section-form-label">{text('label.for.identifier.' + identifierLabel)}</div>
      <div className="section-form-label notyoulink">
        {identifier} &nbsp;
        {state.data.links['urn:vodafoneid:changeidentifier'] && (
          <Link
            id="notyoulink"
            onHandleClick={() => {
              const tealiumPayload = {
                eventCategory: 'link',
                eventLabel: text('not.received.pin.edit.username.label'),
              };

              const smapiPayload = {
                message: `clicks on change username`,
              };

              const actionPayload = {
                link: state.data.links['urn:vodafoneid:changeidentifier'],
                market,
                context: state.data.context,
              };

              handleLinkClick(state.data.context, { tealiumPayload, smapiPayload }, actionPayload);
            }}
            text={text('not.received.pin.edit.username.label')}
          />
        )}
      </div>
    </div>
  );
};

export default IdentifierDisplay;
