// InfoButton.js
import React from 'react';
//TODO: replace button from fe-library
const InfoButton = ({ text, isInfoShown, setInfoShown }) => {
  console.log('inside info button')
  return (
    <>
      <div className="info-button-icon" onClick={() => setInfoShown(true)}></div>

      {isInfoShown && (
        <div className="toast-message-overlay" onClick={() => setInfoShown(false)}>
          <div
            className="toast-message"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="toast-message-header">
              <button
                className="button icon close"
                id="close"
                type="button"
                onClick={() => setInfoShown(false)}
              ></button>
            </div>
            <div className="toast-message-title">{text('credentials.help.text.title')}</div>
            <div className="toast-message-text">{text('credentials.help.text')}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default InfoButton;
