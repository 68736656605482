// RememberCheckbox.js
import React from 'react';
import Checkbox from '../Checkbox/Checkbox';

const RememberCheckbox = ({ isRemember, handleCheckbox, isCheckboxDisabled, label }) => (
  <Checkbox
    id="remember_username"
    checked={isRemember}
    handleCheckbox={handleCheckbox}
    disable={isCheckboxDisabled}
    label={label}
  />
);

export default RememberCheckbox;
