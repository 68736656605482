import { useState, useEffect, useContext } from 'react';
import {text, validateIdentifier} from "../../utils/global";
import { StoreContext } from '../../store/store';

import {
    RESPONSE_ERROR_CLEAR

} from "../../constants/actionTypes";

const useForm = (callback) => {
    const [values, setValue] = useState({password:"",identifier:""});
    const [errors, setErrors] = useState({pwdMsg:"", identifierMsg:"",identifierClass:"",pwdClass:""});
    const [buttonType, setButtonType] = useState("primary");
    const [isValid, setIsValid] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [titleClassName, setTitleClassName] = useState("hidden");
    const [paddingClassName, setPaddingClassName] = useState("paddingTop20");
    const [identifierTitle, setIdentifierTitle] = useState("hidden");
    const [identifierPadding, setIdentifierPadding] = useState("paddingTop20");
    let { state, dispatch } = useContext(StoreContext);
    let identifierId = 'identifier';
    let passwordId = 'password';
    if(state && state.page_look === 'mva') {
        identifierId = 'USERNAME';
        passwordId = 'PASSWORD';
    }
    useEffect(() => {

        // prefill identifier
        if( state.data.userNameHint ){
            setValue(values => ({ ...values, identifier: state.data.userNameHint }));
        }

        if (isSubmitting && buttonType.indexOf('disabled') === -1) {
            let index = 1;
            for (const [key, value] of Object.entries(errors)) {
                if(value.length === 0 && Object.keys(errors).length === index){
                    console.log("--- callback ---");
                    callback();
                }else if(value.length > 0){
                    setIsSubmitting(false);
                }
                index++;
            }
            //DPA PIN
        }else if(isSubmitting && state.data.fields !== undefined && state.data.fields.pin !== undefined){
            callback();
        }
    }, [errors]);

    const handleSubmit = (event) => {
        if (event) event.preventDefault();
        setIsSubmitting(true);
        validateForm('both');
        event.target.blur();
    };

    const handleChange = (event) => {
        event.persist();
        setValue(values => ({ ...values, [event.target.name]: event.target.value.trim() }));
        
        if(event.target.value.trim() !== "" && event.target.value.length > 0 ){
            if(state.errorObj && state.errorObj.msg){
                dispatch({
                    type: RESPONSE_ERROR_CLEAR,
                    payload: {}
                });
            }

            if(event.target.id === identifierId){
                setIdentifierTitle("");
                setIdentifierPadding("");
                //setErrors(errors => ({ ...errors, 'identifierMsg': '' , 'identifierClass': '' }));
            }else{
                setTitleClassName("");
                setPaddingClassName("");
                //setErrors(errors => ({ ...errors, 'pwdMsg': '' , 'pwdClass': ''}));
            }
            let password = event.target.id === passwordId ? event.target.value : values.password;
            let identifier = event.target.id === identifierId ? event.target.value : values.identifier;

            if(state.data.identifier.value === undefined && password.length > 0 && identifier.length > 0){
                setButtonType("primary");
                setIsValid(true);
            }else if(state.data.identifier.value !== undefined && event.target.value.length > 0){
                setButtonType("primary");
                setIsValid(true);
            }

            setIsSubmitting(false);
        }else{
            if(event.target.id === identifierId){
                setButtonType("primary");
                setIsValid(false);
                setIdentifierTitle("hidden");
                setIdentifierPadding("paddingTop20");
            }else{
                setButtonType("primary");
                setIsValid(false);
                setTitleClassName("hidden");
                setPaddingClassName("paddingTop20");
            }
        }
    };

    const resetValues = () => {
        setValue(values => ({ ...values, password : '' , identifier : ''}));
        setButtonType("primary");
        setIsValid(false);
        setIdentifierTitle("hidden");
        setIdentifierPadding("paddingTop20");
        setTitleClassName("hidden");
        setPaddingClassName("paddingTop20");
        setIsSubmitting(false);
        setErrors({});
    }

    const handleBlur = (event) => {
        validateForm(event.target.id);
    };

    function validateForm(id){
        let minLength = process.env.REACT_APP_MIN_PASSWORD_LENGHT;
        if((id === passwordId || id === 'both') && values.password){
            if(minLength !== -1 && values.password.length < minLength){
                //setIsSubmitting(false);

                setErrors(errors => ({ ...errors, 'pwdMsg': text('validation.error.min.length.message.password', [minLength]),'pwdClass':'inline_error' }));
                setButtonType("primary");
                setIsValid(false);
            }else{
                setErrors(errors => ({ ...errors, 'pwdMsg': '', 'pwdClass': '' }));
            }
        }else{
            //Setting empty for password error message (DPA PIN)
            setErrors(errors => ({ ...errors, 'pwdMsg': '', 'pwdClass':'' }));
        }
        // Validate Identifier
        if((id === identifierId || id === 'both' ) && state.data.identifier.value === undefined){
            let errMsg = validateIdentifier(state.data.identifier.type , values.identifier, state.data.context);
            if (errMsg !== false  && errMsg !== undefined){
                //setIsSubmitting(false);
                setErrors(errors => ({ ...errors, 'identifierMsg': errMsg, 'identifierClass': 'inline_error' }));
                setButtonType("primary");
                setIsValid(false);
            }else {
                setErrors(errors => ({ ...errors, 'identifierMsg': '', 'identifierClass': '' }));
            }
        }
    }

    return {
      errors,
      values,
      identifierTitle,
      setIdentifierTitle,
      identifierPadding,
      setIdentifierPadding,
      titleClassName,
      setTitleClassName,
      paddingClassName,
      setPaddingClassName,
      setErrors,
      buttonType,
      setButtonType,
      handleSubmit,
      handleChange,
      resetValues,
      handleBlur,
      isValid,
      setIsValid,
      isSubmitting,
      setIsSubmitting
    };
}


export default useForm;