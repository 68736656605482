import { useCallback } from 'react';
import { getTarget } from '../utils/Helpers';
import { checkWebView } from '../utils/global';
import { getSmapiPageName } from '../constants/smapiMapper';
import { getPageName } from '../constants/tealiumMapper';

export const useLinkHandler = (smapiLogger, trackPageEvent, ServiceRequest, callBack) => {
  
  return useCallback(
    async (context, loggerPayloads, actionPayload) => {
      try {
        // Log actions using both loggers
        const { tealiumPayload, smapiPayload } = loggerPayloads;
        trackPageEvent(getPageName(context), tealiumPayload);

        const smapiMessage = smapiPayload.message;
        smapiLogger.info(`${getSmapiPageName(context)}: ${smapiMessage}`);

        const { link, market } = actionPayload;
        const target = getTarget(market);
        //TODO: not sure we should reset error here
        // resetError();
        // handleLink(link, callBack, target, {
        //   page: context,
        // });
        if (link.type && link.type === 'text/html') {
          window.submitter.flushBufferedEvents().then((res) => {
            console.log('events flushed as user redirected');
          });
          if (target === null && checkWebView() === '_blank') {
            window.open(link.href);
          } else {
            window.location.href = link.href;
          }
          return;
        } else {
          ServiceRequest(link, null, callBack, target, {page: context});
        }
      } catch (error) {
        console.error(`Error handling in link handler}:`, error);
      }
    },
    [smapiLogger, trackPageEvent, ServiceRequest, callBack]
  );
};
