import React, {useState, useEffect} from 'react';
import './FormErrorBox.scss';

const FormErrorBox = ({ iconType, error }) => {
  const [liveMessage, setLiveMessage] = useState('');
  let messageType = iconType.replace('icon', 'message');
   useEffect(() => {
     if (error) {
       setLiveMessage(''); // Reset to force re-announcement
       setTimeout(() => setLiveMessage(error), 100); // Slight delay to ensure screen readers detect it
     }
   }, [error]);

  return (
    <div className={`input_message_wrap ${iconType}`}>
      <div className="input_message_icon_wrap">
        <div className={`input_message_icon ${iconType}`}></div>
      </div>
     
      {/* Hidden live region for forcing screen reader announcement */}
      <div
        id="live-region"
        aria-live="assertive"
        role="alert"
        style={{
          position: 'absolute',
          left: '-9999px', // Hide visually but keep it accessible
        }}
      >
        {liveMessage}
      </div>

      {error && (
        <div role="alert" aria-live="assertive" className={`input_message ${messageType}`}>
          {error}
        </div>
      )}
    </div>
  );
};

export default FormErrorBox;
