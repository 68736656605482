import React, { useState } from 'react';

import './accordion.scss';

const Accordion = (props) => {
  const [open, setOpen] = useState(props.open ? props.open : false);

  return (
    <div className={'vf-accordion-item-wrapper-idp ' + (open ? 'open' : 'close')}>
      <div className="vf-accordion-item-title-idp" onClick={() => setOpen(open ? false : true)}>
        {props.title}
      </div>

      <div className="vf-accordion-item-content-wrapper-idp">
        <div className="vf-accordion-item-content-idp">{props.children}</div>
      </div>
    </div>
  );
};

export default Accordion;
