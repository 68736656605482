// useValidateForm.js
import { useState, useEffect } from 'react';
import useForm from './useForm';
import { getMarket } from '../../utils/Helpers';


const useValidateForm = (useReCAPTCHA, state, ServiceRequest, text, callBack) => {
  const [isRemember, setRemember] = useState(
    state.data.fields !== undefined && state.data.fields.rememberMyUsernameCheckbox !== undefined
      ? state.data.fields.rememberMyUsernameCheckbox[0].value === true
        ? 'checked'
        : ''
      : false
  );
  const [isInfoShown, setInfoShown] = useState(false);
  let identifier =
    (state.data.identifier.type === 'tel' && state.data.identifier.value !== undefined)
      ? '+' + state.data.identifier.value
      : state.data.identifier.value;

  const {
    errors,
    values,
    identifierTitle,
    identifierPadding,
    buttonType,
    setButtonType,
    isValid,
    setIsValid,
    handleSubmit,
    handleChange,
    handleBlur,
    handleFocus,
    isSubmitting,
    resetValues,
    resetError,
    handleKeyPress,
    isCheckboxDisabled,
  } = useForm((e) => onSubmitWithReCAPTCHA(e));

  const { generateToken, resetRecaptcha, recaptchaProps, recaptchaRef } = useReCAPTCHA(
    state?.data?.recaptcha?.['data-sitekey'] || ''
  );

  useEffect(() => {
    resetValues();
    setButtonType('primary');
    setIsValid(false);
    if (state.data.identifier && state.data.identifier.value) {
      setButtonType('primary');
    }
  }, []);

  useEffect(() => {
    // On Error Clear the screen
    if (
      state.errorObj &&
      state.errorObj.msg &&
      (state.errorObj.type === 'error' || state.errorObj.type === 'warning')
    ) {
      setRemember(false);
      resetValues();
    }
  }, [state.errorObj, state.data.message]);

  const onSubmitWithReCAPTCHA = async () => {
    if (recaptchaRef.current) {
      try {
        const token = await generateToken();
        resetRecaptcha();
        submitForm(token);
      } catch (e) {
        console.error('ERROR:', e);
      }
    } else {
      submitForm();
    }
  };

  const submitForm = (token) => {
    let identifier_value = values.identifier;
    const market = getMarket(state);
    if (state.data.identifier.type === 'tel') {
      identifier_value = '+' + market.msisdnPrefix + identifier_value;
    } else if (state.data.identifier.value !== undefined) {
      identifier_value = state.data.identifier.value;
    }

    let payload = {
      identifier: identifier_value.trim(),
      identifierType: state.data.identifier.type,
    };

    if (isRemember !== '') {
      payload['remember_my_username'] = isRemember;
    }

    // Add reCAPTCHA token
    if (token !== undefined) {
      payload['recaptcha_token'] = token;
    }

    payload = JSON.stringify(payload);
    ServiceRequest(state.data.links['urn:vodafoneid:submitIdentifier'], payload, callBack, null, {
      page: state.data.context,
    });  
  };

  return {
    isRemember,
    setRemember,
    isInfoShown,
    setInfoShown,
    errors,
    values,
    identifierTitle,
    identifierPadding,
    buttonType,
    isValid,
    isSubmitting,
    handleSubmit,
    handleChange,
    handleBlur,
    recaptchaProps,
    onSubmitWithReCAPTCHA,
    resetError,
    handleKeyPress,
    isCheckboxDisabled,
    identifier,
  };
};

export default useValidateForm;
