import React, { useState, useContext } from 'react';
import Identifier from '../Identifier/Identifier';
import Checkbox from '../Checkbox/Checkbox';
import OrSeperate from '../OrSeperate/OrSeperate';
import VFLogger from '../../utils/Logger';
import ReCAPTCHA from 'react-google-recaptcha';
import PropTypes from 'prop-types';
import { logFocusedField } from '../../utils/utils';
import { Link, Input, InputOTP } from '@vfid/lib-id-fe-shared';
import WithIntroLink from '../../components/Link/WithIntroLink';
import { hasCallback } from './AppleWatch';
import useAuthenticateForm from './useAuthenticateForm';
import SubmitButton from '../Button/SubmitButton';
import { useLinkHandler } from '../../hooks/useLinkHandler';
import { getMarket } from '../../utils/Helpers';
import IdentifierDisplay from '../Identifier/IdentifierDisplay';
import InfoButton from '../InfoButton/InfoButton';
import { DependencyContext } from '../../context/DependencyContext';

const Authenticate = (props) => {
  const { analyticsTealium, storeContext, useReCAPTCHA, logger, ServiceRequest, text } = useContext(DependencyContext);
  let { state } = useContext(storeContext);
  const marketCode = window.config.market.locale.substring(3, 5).toLowerCase();
  const market = getMarket(state);
  const [isInfoShown, setInfoShown] = useState(false);
  const { trackPageEvent } = analyticsTealium();
  const handleLinkClick = useLinkHandler(logger, trackPageEvent, ServiceRequest, props.RequestCallBack);
  const {
    errors,
    values,
    identifierTitle,
    identifierPadding,
    handleSubmit,
    handleChange,
    handleBlur,
    recaptchaProps,
    handleOTPChange,
    handleKeyPress,
    handlerCheckbox,
    isRememberMe,
    isValid,
    isSubmitting,
    identifier,
    identifierLabel,
    isRememberUsername,
    isOtpValid,
  } = useAuthenticateForm(useReCAPTCHA, state, VFLogger, ServiceRequest, props.RequestCallBack);

  // Detect AppleWatch mobile app
  const appleWatchApp = hasCallback();
  console.log('Apple Watch App: ' + appleWatchApp);

  return (
    <>
      <div className="section-form">
        <div className="box">
          {identifier !== undefined && (
            <IdentifierDisplay
              market={market}
              handleLinkClick={handleLinkClick}
              state={state}
              identifier={identifier}
              text={text}
              identifierLabel={identifierLabel}
            />
          )}

          {identifier === undefined && (
            <>
              {state.data?.showCredentialsHelpText && state.data?.identifier?.type === 'username' && (
                <InfoButton text={text} isInfoShown={isInfoShown} setInfoShown={setInfoShown} />
              )}
              <Identifier
                identifierTitle={identifierTitle}
                className={errors.identifierClass}
                identifierPadding={identifierPadding}
                market={market}
                handleChange={handleChange}
                handleKey={handleKeyPress}
                handleBlur={handleBlur}
                value={values.identifier}
                errMsg={errors.identifierMsg}
                dataTestID={'USERNAME'}
                id={state.page_look === 'mva' ? 'USERNAME' : ''}
                handleFocus={() => logFocusedField(state.data.context, state.data.identifier.type)}
              ></Identifier>
            </>
          )}

          {state.data.links && state.data.links['urn:vodafoneid:forgotUsername'] && (
            <Link
              id="forgotUsername"
              onHandleClick={() => {
                const tealiumPayload = {
                  eventCategory: 'link',
                  eventLabel: text('forgot.username.link.text'),
                };

                const smapiPayload = {
                  message: `clicks on forget username`,
                };

                const actionPayload = {
                  link: state.data.links['urn:vodafoneid:forgotUsername'],
                  market,
                  context: state.data.context,
                };
                handleLinkClick(state.data.context, { tealiumPayload, smapiPayload }, actionPayload);
              }}
              text={text('forgot.username.link.text')}
            ></Link>
          )}

          {state.data?.links?.['urn:vodafoneid:authenticate'] && (
            <Input
              id={state.page_look === 'mva' ? 'PASSWORD' : 'password'}
              name="password"
              type="password"
              label={text('label.for.existing.password')}
              minLength={10}
              labelType="standalone"
              placeholder={text('placeholder.for.existing.password')}
              error={errors.pwdMsg}
              className="" // Add the necessary class here
              value={values.password}
              dataTestID="PASSWORD"
              onHandleBlur={handleBlur}
              onHandleChange={handleChange}
              onHandleKeyDown={handleKeyPress}
              onHandleFocus={(e) => logFocusedField(state.data.context, 'password')}
            />
          )}

          {!(appleWatchApp && state.data?.hideRegistrationResetPwdLinkForAppleWatch) &&
            state.data.links?.['urn:vodafoneid:resetpassword'] && (
              <Link
                id="forgotPassword"
                onHandleClick={() => {
                  const tealiumPayload = {
                    eventCategory: 'link',
                    eventLabel: text('forgot.password.link.text'),
                  };

                  const smapiPayload = {
                    message: `clicks on forget password`,
                  };

                  const actionPayload = {
                    link: state.data.links['urn:vodafoneid:resetpassword'],
                    market,
                    context: state.data.context,
                  };

                  handleLinkClick(state.data.context, { tealiumPayload, smapiPayload }, actionPayload);
                }}
                text={text('forgot.password.link.text')}
              />
            )}

          {state.data?.fields?.pin && (
            <>
              <OrSeperate />
              <div className="section-otp-input">
                <InputOTP
                  id="vf-input-otp"
                  label={text('label.for.dpa_pin')}
                  size={4}
                  onHandleChange={handleOTPChange}
                  onHandleKeyDown={handleSubmit}
                />
              </div>
            </>
          )}

          {marketCode !== 'gb' &&
            marketCode !== 'es' &&
            state.page_look !== 'mva' &&
            state.data.fields?.rememberMyUsernameCheckbox && (
              //Remember my username checkbox
              /**
               * https://cps.jira.agile.vodafone.com/browse/VIDP-17149
               * Temporary removing remember me and and remembermyusername for gb and es
               *
               */
              <Checkbox
                id="remember_my_username"
                checked={isRememberUsername}
                handleCheckbox={handlerCheckbox}
                disable={false}
                label={text(state.data.fields.rememberMyUsernameCheckbox[0].name)}
              />
            )}

          {state.data.remember_me && state.data.remember_me.display === true && (
            <Checkbox
              id="remember_me"
              checked={isRememberMe}
              handleCheckbox={(e) => handlerCheckbox(e)}
              disable={false}
              label={text('remember.me.checkbox.label')}
            ></Checkbox>
          )}
        </div>

        {state.data.links['urn:vodafoneid:authenticate'] && (
          <SubmitButton
            isSubmitting={isSubmitting}
            isValid={isValid || isOtpValid}
            handleSubmit={handleSubmit}
            text={text}
            trackPageEvent={trackPageEvent}
            state={state}
          />
        )}

        {!(appleWatchApp && state.data?.hideRegistrationResetPwdLinkForAppleWatch) &&
          state.data.links['urn:vodafoneid:register'] && (
              <WithIntroLink
                id="register"
                onHandleClick={() => {
                  handleLinkClick(
                    state.data.context,
                    {
                      tealiumPayload: {
                        eventCategory: 'link',
                        eventLabel: text('register.link.text'),
                      },
                      smapiPayload: { message: 'clicks on register link' },
                    },
                    {
                      link: state.data.links['urn:vodafoneid:register'],
                      market,
                      context: state.data.context,
                    }
                  );
                }}
                intro={text('register.link.intro.text')}
                text={text('register.link.text')}
              />
          )}

        {appleWatchApp && state.data?.hideRegistrationResetPwdLinkForAppleWatch && (
          <div className="help_text">{text('onenumber.guidance.text')}</div>
        )}

        {state.data && state.data.recaptcha && (
          <>
            <ReCAPTCHA {...recaptchaProps} />
          </>
        )}
      </div>
    </>
  );
};

Authenticate.propTypes = {
  RequestCallBack: PropTypes.func,
};
export default Authenticate;
