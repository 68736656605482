import React, {
	useEffect,
	useContext,
	useState
} from "react";
import ReactDOM from "react-dom";

import {
	StoreContext
} from "../../store/store";

// Stying
import "./pop-up.scss";

const PopUp = props => {
	const globalState = useContext(StoreContext);
	const { dispatch } = globalState;

	useEffect(() => {
		// PopUp loaded

		const close = (e) => {
			if (e.keyCode === 27) {
				props.onClickOutSide();
			}
		}

		window.addEventListener('keydown', close);
		
		return () => {
			window.removeEventListener('keydown', close);
		}

		// return () => {
		// 	// PopUp unloaded
		// };

	}, []);

	const handleClickOutside = () => {
		if(props.onClickOutSide){
			props.onClickOutSide();
		}
	}

	const handleKeyDown = () => {
		// console.log("handleKeyDown");
	}

	return ReactDOM.createPortal(
		<div className="idp-wrapper">
			<div className="vf-popup-overlay-idp" onClick={handleClickOutside} onKeyDown={handleKeyDown}>

				<div className={"vf-popup-wrapper-idp" + (globalState.state.popup ? " " + globalState.state.popup.toLowerCase() : "")}>
					<div className="vf-popup" onClick={(e) => { e.stopPropagation() }}>

						{props.children}

					</div>
				</div>

			</div>
		</div>
		, document.getElementById("root"));
}

export default PopUp;
