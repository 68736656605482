import axios from 'axios';
import VFLogger from './Logger';
import { checkWebView } from './global';
import { INITIAL_START } from '../constants/actionTypes';
import { enabledContext } from '../constants/context';
import { replaceIFrameSrc } from '../utils/utils';
import { getSmapiPageName } from '../constants/smapiMapper';

export const configRequest = (dispatch) => {
  return new Promise(function (resolve, reject) {
    dispatch({
      type: INITIAL_START,
    });

    console.log('REACT_APP_BACKEND_ENDPOINT: ' + process.env.REACT_APP_BACKEND_ENDPOINT);
    console.log('REACT_APP_INDEX_ENDPOINT: ' + process.env.REACT_APP_INDEX_ENDPOINT);

    let index = process.env.REACT_APP_BACKEND_ENDPOINT + process.env.REACT_APP_INDEX_ENDPOINT;

    console.log('NODE_ENV: ' + process.env.NODE_ENV);

    if (process.env.NODE_ENV !== 'development') {
      index = window.location.href.substr(0, 11) + index.substr(8);
    }

    const options = {
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json; charset=utf-8',
      },
    };

    // Console log
    console.log('URL for config fetch : ' + index);
    axios
      .get(index, { options })
      .then((response) => {
        /** WAF FIX */
        const contentType = response.headers['content-type'];
        if (contentType && contentType.includes('text/html')) {
          VFLogger.info(`${getSmapiPageName(page)}: WAF reCAPTCHA loaded`);
          document.body.innerHTML = replaceIFrameSrc(response.data);
          return;
        }
        /** WAF FIX END */
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAuthRequest = (authUrl, dispatch, requestObj) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: INITIAL_START,
    });
    const { url, ajaxHeaders } = parseRequest(authUrl, '');
    VFLogger.info(`Authorize: request: ${url}`);

    axios({
      method: authUrl.method,
      url: url,
      data: requestObj ? requestObj : null,
      headers: ajaxHeaders,
      timeout: process.env.REACT_APP_AJAX_TIMEOUT,
      withCredentials: true,
    })
      .then((response) => {
        /** WAF FIX */
        const contentType = response.headers['content-type'];
        if (contentType && contentType.includes('text/html')) {
          VFLogger.info(`Authorize: WAF reCAPTCHA loaded`);
          document.body.innerHTML = replaceIFrameSrc(response.data);
          return;
        }
        /** WAF FIX END */
        if (process.env.NODE_ENV === 'development') {
          response.data = response.data[enabledContext.context];
        }
        VFLogger.info(`${getSmapiPageName(response.data.context)}: loaded`);
        resolve(response);
      })
      .catch((error) => {
        VFLogger.info(`Authorize: failed: ${error}`);
        reject(error);
      });
  });
};

const parseRequest = (requestUrl, target) => {
  //Console log
  console.log('Parse Service Request call : ' + requestUrl);

  let ajaxHeaders = {
    Accept: 'application/json',
    'Content-type': 'application/json; charset=utf-8',
  };

  for (var key in requestUrl.headers) {
    if (Object.prototype.hasOwnProperty.call(requestUrl.headers, key)) {
      ajaxHeaders[key] = requestUrl.headers[key];
    }
  }

  let url = requestUrl.href;
  //Sending redirect url for confirm reset password contect to show button in end flow context
  if (window.context !== undefined && window.context.context === 'CONFIRM_RESET_PASSWORD') {
    let redirectURL = window.location.search;
    url = url + redirectURL;
  }
  return {
    url,
    ajaxHeaders,
  };
};

export const ServiceRequest = (requestUrl, requestObj = null, callBack, target = null, logInfo = null) => {
  //Console log
  console.log('Service Request call : ' + requestUrl);
  const page = logInfo ? logInfo.page : '';
  const { url, ajaxHeaders } = parseRequest(requestUrl, target);
  // TODO: to remove this redirect logic, as primary goal of this
  // function is to make ajax call, which breask SRP of SOLID
  if (requestUrl.type && requestUrl.type === 'text/html') {
    if (target === null && checkWebView() === '_blank') {
      window.open(requestUrl.href);
    } else {
      window.location.href = requestUrl.href;
    }
    return;
  }
  VFLogger.info(`${getSmapiPageName(page)}: request ${url}`);

  axios.defaults.withCredentials = true;
  let requestObjArr = [];
  if (requestObj && typeof requestObj === 'string') {
    requestObjArr = JSON.parse(requestObj);
  }
  axios({
    method: requestUrl.method,
    url: url,
    data: requestObj,
    headers: ajaxHeaders,
    timeout: process.env.REACT_APP_AJAX_TIMEOUT,
    withCredentials: true,
  })
    .then((response) => {
      console.log(response.data);
      /** WAF FIX */
      const contentType = response.headers['content-type'];
      if (contentType && contentType.includes('text/html')) {
        document.body.innerHTML = replaceIFrameSrc(response.data);
        return;
      }
      /** WAF FIX END */
      if (process.env.NODE_ENV === 'development') {
        response.data = response.data[enabledContext.context];
      }
      if (response.data.context) {
        if (response.data.context === 'FOLLOW') {
          VFLogger.info(
            `${getSmapiPageName(page)}: process: ${getSmapiPageName(response.data.context)}: ${
              response.data.links['urn:vodafoneid:follow'].href
            }`
          );
        } else {
          VFLogger.info(`${getSmapiPageName(response.data.context)}: loaded`);
          VFLogger.info(`${getSmapiPageName(page)}: process: ${getSmapiPageName(response.data.context)}`);
        }
      }
      if (requestObjArr && requestObjArr['recaptcha_token']) {
        VFLogger.info(`${getSmapiPageName(page)}: google reCAPTCHA Challenge Passed`);
      }
      callBack(response);
    })
    .catch((error) => {
      if (
        requestObjArr &&
        requestObjArr['recaptcha_token'] &&
        error.response.data.message === 'Captcha token not valid'
      ) {
        VFLogger.info(`${getSmapiPageName(page)}: google reCAPTCHA Verification Unsuccessful`);
      }
      VFLogger.info(`${getSmapiPageName(page)}: failed: ${error}`);
      let errorObj = {};

      if (error.response !== undefined) {
        errorObj = {
          data: error.response.data,
          status: error.response.status,
        };
      } else {
        let status = 500; // Internal server error
        //Check the http call timeout
        if (error.code === 'ECONNABORTED') {
          status = 408; //Timeout code
        }
        errorObj = {
          data: 'oops.message',
          status: status,
        };
      }
      callBack(errorObj);
    });
};

export default ServiceRequest;

//TODO: get rid of this handleLink function
export function handleLink(link, callBack, target = null, logInfo = null) {
  ServiceRequest(link, null, callBack, target, logInfo);
}
