import React, { useState } from 'react';

// Stying
import "./tabs.scss";

const Tabs = ({ defaultTab, children }) => {
    const [activeTab, setActiveTab] = useState(defaultTab || 0);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };
    return (
        <div className="vf-tabs-wrapper-idp">
            <div className="vf-tabs">
                {React.Children.map(children, (tab, index) => 
                    tab && (
                        <div key={index} className={"vf-tab-wrapper " + (index === activeTab ? "selected" : "")} onClick={() => handleTabClick(index)}>
                            <div className={"vf-tab-title" + (tab.props.icon ? " icon " + tab.props.icon : "")}>
                                {tab.props.title}
                            </div>
                        </div>
                    ))}
            </div>

            <div className="vf-tabs-content">
                {React.Children.toArray(children)[activeTab]}
            </div>
        </div>
    );
};

const Tab = ({ title, children }) => {
    return (
        <>
            <div className="vf-tabs-content-title">
                {title}
            </div>
            <div className="vf-tabs-content-section">
                {children}
            </div>
        </>
    );
};

export { Tabs, Tab };