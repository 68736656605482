import { countryMarket } from "./global";
export function getMarket(state) {
    let market = '';
    if (window.config !== undefined) {
      const country = state.data.nvMarket !== undefined ? state.data.nvMarket.toUpperCase() : undefined;
      market = countryMarket(state.data.nv, country);
    }
    return market
}


export function getTarget(market) {
  let target = null;
  const opco = ['ES', 'IE'];
  if (market.countryCode !== undefined && opco.indexOf(market.countryCode) >= 0) {
    target = 'self';
  }
  return target;
}