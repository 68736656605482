import React from 'react';
import './Link.scss';
import { Link } from '@vfid/lib-id-fe-shared';

const WithIntroLink = (props) => {
  return (
    <div id="with-link-wrapper">
        {props.intro && <React.Fragment>{props.intro}&nbsp;</React.Fragment>}
        <a id={props.id} onClick={props.handleLink}>
          {props.label}
        </a>
        <Link id={props.id} onHandleClick={props.onHandleClick} text={props.text} />
    </div>
  );
};

export default WithIntroLink;
