import { useEffect, useState } from 'react';
import useForm from './useForm';
import { getSmapiPageName } from '../../constants/smapiMapper';
import { encryptPassword } from '../../utils/global';

const useAuthenticateForm = (useReCAPTCHA, state, logger, ServiceRequest, callBack) => {
  const { generateToken, resetRecaptcha, recaptchaProps, recaptchaRef } = useReCAPTCHA(
    state?.data?.recaptcha?.['data-sitekey'] || ''
  );
  
  let identifier =
    state.data.identifier.type === 'tel' ? '+' + state.data.identifier.value : state.data.identifier.value;

    const [OTP, setOTP] = useState('');
    const [isOtpValid, setOtpValid] = useState(false);
  let identifierLabel = state.data.identifier.type === 'tel' ? 'msisdn' : state.data.identifier.type;
  const [isRememberMe, setRememberMe] = useState(
    state.data.remember_me && state.data.remember_me.value && state.data.remember_me.value === true ? true : false
  );

  const [isRememberUsername, setRememberUsername] = useState(
    state.data.fields !== undefined && state.data.fields.rememberMyUsernameCheckbox !== undefined
      ? state.data.fields.rememberMyUsernameCheckbox[0].value === true
        ? 'checked'
        : ''
      : false
  );
  const {
    errors,
    values,
    identifierTitle,
    identifierPadding,
    setTitleClassName,
    setPaddingClassName,
    setErrors,
    buttonType,
    setButtonType,
    handleSubmit,
    handleChange,
    resetValues,
    handleBlur,
    isValid,
    setIsValid,
    setIsSubmitting,
    isSubmitting,
  } = useForm((e) => onSubmitWithReCAPTCHA(e));

  useEffect(() => {
    formatInputWithIcon()
  },[])

  useEffect(() => {
    //On Error Clear the screen
    if (state.errorObj && state.errorObj.msg) {
      setOTP('');
      if (state.data.fields !== undefined && state.data.fields.pin !== undefined) {
        // Set the first PIN box to active
        if (document.getElementById('otpInputWrap')) {
          document.getElementById('otpInputWrap').firstElementChild.classList.remove('initial_state');
        }
      }
      resetValues();
      setRememberUsername(false);
      setRememberMe(false);
    }

    // reset reCAPTCHA
    if (recaptchaRef.current) {
      resetRecaptcha();
    }
  }, [state.errorObj]);

  const onSubmitWithReCAPTCHA = async () => {
    console.log('--- onSubmitWithReCAPTCHA ---');
    // Submit with reCAPTCHA
    if (recaptchaRef.current) {
      try {
        const token = await generateToken();
        console.log('token: ' + token);
        logger.info(`${getSmapiPageName(state.data.context)}: google reCAPTCHA loaded`);
        resetRecaptcha();
        submitForm(token);
      } catch (e) {
        console.log('ERROR: ' + e);
      }
    } else {
      submitForm();
    }
  };

  function formatInputWithIcon() {
    document.querySelectorAll('.vf-input-icon').forEach(icon => {
    let input = icon.previousElementSibling; // Get the next sibling element (input field)

    if (input && input.matches('input.vf-input[type="password"]')) {
        let wrapper = document.createElement('div'); // Create wrapper div
        wrapper.classList.add('vf-input-password-icon'); // Add class to wrapper

        // Insert the wrapper before the icon
        icon.parentNode.insertBefore(wrapper, icon);

        // Move both elements inside the wrapper
        wrapper.appendChild(icon);
        wrapper.appendChild(input);
    }
});

  }
  //Form Submit function
  function submitForm(token) {
    logger.info(`${getSmapiPageName(state.data.context)}: form submit`);
    if (OTP.length === 1) {
      if (values.password.length > 1) {
        values.password = '';
        setButtonType('primary');
        setIsValid(false);
        setTitleClassName('hidden');
        setPaddingClassName('paddingTop20');
        setErrors({});
      }
    } else {
      setButtonType('primary');
      setIsValid(false);
      setIsSubmitting(true);
      let submitLink = state.data.links['urn:vodafoneid:authenticate'];
      let jsonObj = {
        identifier: state.data.identifier.value === undefined ? values.identifier : identifier,
        identifierType: state.data.identifier.type,
      };

      if (state.data['consent_status']) {
        jsonObj['consentStatus'] = state.data['consent_status'];
        jsonObj['appId'] = state.data['app_id'];
      }

      if (state.data['remember_device']) {
        jsonObj['rememberResourceId'] = state.data['remember_device'];
      }

      if (isRememberMe) {
        console.log('remember=', isRememberMe);
        jsonObj['remember_me'] = isRememberMe;
      }

      if (isRememberUsername) {
        jsonObj['remember_my_username'] = isRememberUsername;
      }

      if (values.password !== '' && state.data.links['urn:vodafoneid:authenticate'] !== undefined) {
        submitLink = state.data.links['urn:vodafoneid:authenticate'];
        const encrypedPwd = encryptPassword(state.data.links['urn:vodafoneid:authenticate'], values.password);
        jsonObj['password'] = encrypedPwd;
      }

      // DPA PIN
      if (OTP.length > 1) {
        jsonObj['pin'] = OTP;
      }

      // Add reCAPTCHA token
      if (token !== undefined) {
        jsonObj['recaptcha_token'] = token;
      }

      const payload = JSON.stringify(jsonObj);

      ServiceRequest(submitLink, payload, callBack, null, {
        page: state.data.context,
      });
      // TODO: To remove this commented code
      //   if (state.page_look === 'mva') {
      //     dispatch({
      //       type: SPLASH_LOAD,
      //       splash: true,
      //     });
      //   }
    }
  }

  function handleOTPChange(OTP) {
    let otpValue = OTP;

    if (otpValue !== '' && otpValue.length === 4) {
      setButtonType('primary');
      setOtpValid(true);
      setIsValid(true);
    } else {
      setButtonType('primary');
      setOtpValid(false);
      setIsValid(false);
    }

    setOTP(otpValue);
  }

  //on Key Press Handler for Key Press on MSISDN input field
  function handleKeyPress(event) {
    if (event.target.id.toLowerCase() === 'password' && OTP.length > 1) {
      setOTP('');
      if (document.getElementById('otpInputWrap')) {
        document.getElementById('otpInputWrap').firstElementChild.classList.remove('initial_state');
      }
    }
    var charCode = event.which ? event.which : event.keyCode;
    if (charCode !== 13 && charCode !== 32) {
      return true;
    } else if (charCode == 13) {
      handleSubmit(event);
    }
  }

  //Checkbox Handler
  function handlerCheckbox(e) {
    if (e.target.id === 'remember_me') {
      setRememberMe(e.target.checked);
      if (e.target.checked) {
        logger.info(`${getSmapiPageName(state.data.context)}: remember me checked`);
      } else {
        logger.info(`${getSmapiPageName(state.data.context)}: remember me unchecked`);
      }
    } else {
      setRememberUsername(e.target.checked);
    }
  }

  return {
    errors,
    values,
    identifierTitle,
    identifierPadding,
    setTitleClassName,
    setPaddingClassName,
    setErrors,
    buttonType,
    setButtonType,
    handleSubmit,
    handleChange,
    resetValues,
    handleBlur,
    recaptchaProps,
    handleOTPChange,
    handleKeyPress,
    handlerCheckbox,
    isRememberMe,
    setRememberMe,
    isRememberUsername,
    setRememberUsername,
    isValid,
    isSubmitting,
    identifier,
    identifierLabel,
    isOtpValid,
  };
};

export default useAuthenticateForm;
