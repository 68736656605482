import React from "react";
import analyticsTealium from "../utils/Analytics/analytics";
import { StoreContext } from "../store/store";
import { useReCAPTCHA } from "../hooks/useReCAPTCHA";
import VFLogger from "../utils/Logger";
import ServiceRequest from "../utils/Services";
import { text } from "../utils/global";

export const DependencyContext = React.createContext();
//TODO: Need to crete global useForm hook, so it can be used as dependency
// as of now we have multiple useForm based on component

const DependencyProvider = ({ children }) => {
  return (
    <DependencyContext.Provider
      value={{
        analyticsTealium: analyticsTealium,
        storeContext: StoreContext,
        // formHook: useForm,
        useReCAPTCHA: useReCAPTCHA,
        logger: VFLogger,
        ServiceRequest: ServiceRequest,
        // linkHandler: handleLink,
        text: text,
      }}
    >
      {children}
    </DependencyContext.Provider>
  );
};
export default DependencyProvider;