export const enabledContext = {
  //context: 'Validate',
 // context: "Validate_GB",
  //context: "Validate_PT",
  // context: "Validate_ES",
  //context: 'Validate_forgot_username',
  context: 'Authenticate',
  // context: "Authenticate_NV",
  // context: 'EndFlowLoading',
  // context: "Authenticate_DE"
  // context: "Authenticate_GB",
  //context: 'Update_Profile',
  //context: 'END_FLOW_LOADING',
  //context: 'Confirm_Pin_VF',
  //context: 'Confirm_Pin_NV',
  //context: 'Confirm_Pin_NV_MISDN',
  //context: 'Registration',
  //context: 'ES_EMAIL_VERIFICATION',
  //context: 'Confirm_Reset_Password',
  //context: 'Confirm_Pin_Password',
  // context: "Error"
  //context: 'WelcomeBack_VF',
  // context: "WelcomeBack_IT"
  //  context: "WelcomeBack_NV"
  // context: "WelcomeBack_DE",
  // context: "NonVodafoneEnabled"
  //context: 'Follow',
  //context: 'End_Flow',
  // context: "End_Flow_Pwd"
  //context: "Confirm_Reset_Pwd_Error"
  // context: "End_flow_Pwd_Expire",
  // context: "End_flow_Success"
  // context: "End_Flow_Pwd",
  //context: 'Account_Selection',
  context: 'Account_Selection_Soho',
};
